import React, { FormEvent, useContext, useEffect, useMemo, useState } from 'react'
import { connect, ignoreState, useLape, useLapeEffect } from 'lape'
import get from 'lodash/get'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import * as yup from 'yup'
import { ValidationError } from 'yup'
import { FieldOptions } from '@src/interfaces'
import { ChangelogApi } from '@src/interfaces/data'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'

const StyledForm = styled.form`
  display: contents;
`

const FormContext = React.createContext({} as LapeFormInterface<any>)

export const useLapeContext = <T extends {}>(): LapeFormInterface<T> => {
  return useContext(FormContext)
}

// TODO move touched logic to onBlur for text fields, make current touched into dirty
interface FieldState {
  touched: boolean
}

export const useLapeField = <V extends any = any>(name: string) => {
  const form = useLapeContext<{ field_options?: FieldOptions }>()
  // lape state update causes additional re-render that resetting the HTMLEditor cursor position
  const [touched, setTouched] = useState(false)

  const cleanErrorsIfNeeded = () => {
    if (get(form.apiErrors, name)) {
      set(form.apiErrors, name, undefined)
    }

    // FIXME: this is here just because Form/Form.tsx submit function catch has `form.errors = errors` for backwards compatibility
    // and I couldn't figure out a better way to unset the error for a field if there is no FE validator for the field.
    // Should eventually be removed
    if (!get(form.validation, name) && !!get(form.errors, name)) {
      set(form.errors, name, undefined)
    }
  }

  useEffect(() => {
    cleanErrorsIfNeeded()
  }, [get(form.values, name)])

  useLapeEffect(() => {
    form.fields[name] = {
      touched,
    }
    return () => {
      delete form.fields[name]
    }
  })

  const hidden = form?.values?.field_options?.no_access?.includes(name)
  const disabled = form.disabled || form?.values?.field_options?.read_only?.includes(name)

  const onChange = (value: any) => {
    set(form.values, name, value)
    setTouched(true)
  }
  return {
    value: get(form.values, name) as V,
    initialValue: get(form.initialValues, name),
    error: get(form.errors, name),
    apiError: get(form.apiErrors, name),
    changelog: get(form.changelog, name),
    touched,
    hidden,
    disabled,
    onChange,
    cleanErrors: cleanErrorsIfNeeded,
  }
}

export type FormError<T> = {
  [P in keyof T]?: FormError<T[P]> extends string | number | boolean
    ? string
    : FormError<T[P]>
}

export interface LapeFormInterface<T> {
  values: T
  initialValues: Partial<T>
  errors: FormError<T>
  apiErrors: FormError<T>
  disabled: boolean
  dirty: boolean
  isSubmitting: boolean
  hasSubmitted: boolean
  submitFailed: boolean
  valid: boolean
  loading?: boolean
  validation?: any
  changelog?: object
  changelogApi?: ChangelogApi
  submit: (event?: FormEvent<HTMLFormElement>) => Promise<T>
  reset: (newValues: T, initialValues?: Partial<T>) => void
  fields: { [name: string]: FieldState }
  hash: string // used to handle any form change in effects because useLapeEffect is not easy to use
  /** @deprecated - used while migrating from final form, mutate values directly in new forms */
  change: (path: string, value: any) => void
}

const validate = (values: object, validation: any) => {
  let newErrors = {}
  try {
    if (!isEmpty(validation)) {
      yup.object(validation).validateSync(values, { abortEarly: false })
    }
  } catch (errors) {
    // for lazy tab validation
    if (!errors.inner) {
      newErrors = errors
    }
    ;(errors as ValidationError).inner?.forEach(error => {
      set(newErrors, error.path!, error.message?.replace(/[_.]/g, ' '))
    })
  }
  return newErrors
}

export interface LapeFormProps<T> {
  initialValues?: Partial<T>
  validation?: object
  children: React.ReactNode
  onSubmit: (form: LapeFormInterface<T>) => Promise<T>
  disabled?: boolean
  loading?: boolean
  changelog?: object
  changelogApi?: ChangelogApi
  disableValidation?: boolean
  fieldsToExclude?: string[]
  onChange?: (values: T) => void
}

const getFormHash = (values: unknown): string =>
  btoa(encodeURIComponent(JSON.stringify(values)))

const LapeForm = <T extends {}>({
  initialValues = {},
  validation = {},
  children,
  onSubmit,
  loading,
  disabled,
  changelog,
  changelogApi,
  disableValidation,
  fieldsToExclude,
  onChange, // useLapeEffect is buggy
}: LapeFormProps<T>) => {
  const values = useMemo(() => cloneDeep(initialValues as T), [initialValues])

  const form: LapeFormInterface<T> = useLape({
    values,
    hash: getFormHash(values),
    initialValues: useMemo(() => cloneDeep(initialValues), [initialValues]),
    errors: {},
    apiErrors: {},
    disabled: !!disabled,
    dirty: false,
    valid: true,
    loading: useMemo(() => loading, [loading]),
    isSubmitting: false,
    hasSubmitted: false,
    submitFailed: false,
    fields: {},
    validation: ignoreState(useMemo(() => cloneDeep(validation), [])),
    changelog,
    changelogApi,
    submit,
    reset,
    change,
  })

  // Update submit function when the callback changes to prevent cached lexical environment
  useEffect(() => {
    form.submit = submit
  }, [onSubmit])

  useEffect(() => {
    form.loading = loading
  }, [loading])

  useEffect(() => {
    if (!isEqual(initialValues, form.initialValues)) {
      form.values = cloneDeep(initialValues as T)
      form.initialValues = cloneDeep(initialValues as T)
    }
  }, [initialValues])

  useEffect(() => {
    form.disabled = disabled || form.disabled
  }, [disabled, form.disabled])

  async function submit(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault?.()
    try {
      form.isSubmitting = true
      form.hasSubmitted = true
      const data = await onSubmit(form)
      form.isSubmitting = false
      form.submitFailed = false
      return data
    } catch (e) {
      form.isSubmitting = false
      form.submitFailed = true
      throw e
    }
  }

  async function reset(newValues: T, initial?: Partial<T>) {
    form.values = newValues
    form.initialValues = initial || cloneDeep(newValues)
    form.hash = getFormHash(values)
  }

  function change(path: string, value: any) {
    set(form.values, path, value)
    form.hash = getFormHash(values)
  }

  useLapeEffect(() => {
    if (disableValidation) {
      return
    }

    const errors = validate(form.values, form.validation)
    form.errors = errors
    form.valid = !Object.keys(errors).length
  })

  const checkEquality = (): boolean => {
    if (fieldsToExclude) {
      const newValues = omit(form.values, fieldsToExclude)
      const oldValues = omit(form.initialValues, fieldsToExclude)

      return isEqual(newValues, oldValues)
    }
    return isEqual(form.values, form.initialValues)
  }

  const updateDirty = () => {
    if (checkEquality()) {
      if (form.dirty) {
        form.dirty = false
      }
    } else if (!form.dirty) {
      form.dirty = true
    }
  }

  useEffect(() => {
    updateDirty()
  }, [fieldsToExclude])

  useLapeEffect(() => {
    updateDirty()
    onChange && onChange(form.values)
    form.hash = getFormHash(form.values)
  })

  return (
    <FormContext.Provider value={form}>
      <StyledForm onSubmit={e => submit(e)}>{children}</StyledForm>
    </FormContext.Provider>
  )
}

export default connect(LapeForm)
